import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export type AvoidMistakesSectionType = {
  className?: string
}

const Mistakes = styled.section`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 192px 208px;
  box-sizing: border-box;
  gap: 152px;
  max-width: 100%;
  z-index: 4;
  text-align: left;
  font-size: 22px;
  color: #222;
  font-family: 'SF Compact';
  @media screen and (max-width: 1225px) {
    gap: 76px;
  }
  @media screen and (max-width: 850px) {
    gap: 38px;
    padding-top: 125px;
    padding-bottom: 125px;
    box-sizing: border-box;
  }

  @media screen and (max-width: 1000px) {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
`
const SectionTitle = styled.div`
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  @media screen and (max-width: 450px) {
    font-size: 18px;
  }
`
const IconsGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 89px;
  color: #6360ff;

  @media screen and (max-width: 1360px) {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 89px;
  }

  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 850px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 786px) {
    flex-direction: column;
  }
`
const IconAndTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
`
const IconH3 = styled.h3`
  margin: 0;
  font-size: 29px;
  font-weight: 500;

  @media screen and (max-width: 450px) {
    font-size: 23px;
  }
`
const Title = styled.div`
  text-align: center;
  font-size: 21px;
  color: #b0b0b0;
  @media screen and (max-width: 450px) {
    font-size: 17px;
  }
`

const AvoidMistakesSection: FC = () => {
  const { t } = useTranslation()

  const data = [
    {
      icon: '\u{10131C}',
      title: t('section.avoidMistakes.unneededFeatures'),
    },
    {
      icon: '\u{100A7C}',
      title: t('section.avoidMistakes.wrongTechnology'),
    },
    {
      icon: '\u{1004D0}',
      title: t('section.avoidMistakes.wrongSpecialists'),
    },
    {
      icon: '\u{1008BE}',
      title: t('section.avoidMistakes.recruitingAndHiring'),
    },
  ]

  return (
    <Mistakes>
      <SectionTitle>{t('section.avoidMistakes.title')}</SectionTitle>
      <IconsGroup>
        {data.map((element, index) => (
          <IconAndTextDiv key={index}>
            <IconH3>{element.icon}</IconH3>
            <Title>{element.title}</Title>
          </IconAndTextDiv>
        ))}
      </IconsGroup>
    </Mistakes>
  )
}

export default AvoidMistakesSection
