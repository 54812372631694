import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export type WeDontWantClientsSectionType = {
  className?: string
}

const WeDontWant = styled.span`
  font-weight: 200;
`
const Partnerships = styled.i`
  font-weight: 200;
`
const TextContainerH1 = styled.div`
  font-family: 'Feature Display';
  font-weight: 200;
  padding: 7rem;
  font-size: min(15vw, 8rem);
  line-height: 1.1;
  max-width: 60rem;
`
const WeDontWantClientsWeWantWrapper = styled.section`
  background-color: #6360ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #fff;
`

const WeDontWantClientsSection: FC = () => {
  const { t } = useTranslation()
  return (
    <WeDontWantClientsWeWantWrapper>
      <TextContainerH1>
        <WeDontWant>
          {t('section.weDontWant.titleFirst')}
          <Partnerships>{t('section.weDontWant.titleItalics')}</Partnerships>
        </WeDontWant>
      </TextContainerH1>
    </WeDontWantClientsWeWantWrapper>
  )
}

export default WeDontWantClientsSection
