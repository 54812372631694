import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import SuccessProduct, {
  SuccessProductType,
} from '../../components/SuccessProduct'
import NextPreviousControls from '../../components/NextPreviousControls'
import { useWindowSize } from '@uidotdev/usehooks'
import { useTranslation } from 'react-i18next'
import { breakpoints } from './OpeningSection'

const FrameParentRoot = styled.section`
  font-size: 22px;
  font-family: 'SF Compact';

  --_paddingLeft: 7.5rem;
  --_titleToProductsPadding: 5rem;
  --_nextItemGap: 0;
  --_imagesWidth: 15.625rem;
  --_imagesToTextGap: 3.75rem;
  --_productTitleToTextGap: 5rem;
  --_titleMarginBottom: 7.5rem;

  @media (width <= ${breakpoints.SMALL}) {
    --_paddingLeft: 4rem;
  }

  @media (width <= ${breakpoints.XSMALL}) {
    --_paddingLeft: 2.5rem;
    --_nextItemGap: var(--_paddingLeft);
    --_productTitleToTextGap: 2.5rem;
    --_titleMarginBottom: 3.75rem;
    --_titleToProductsPadding: 4rem;
  }
`
const Title = styled.h1`
  margin: 0;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 600;
  color: #b1b1b1;
  padding-left: var(--_paddingLeft);
  padding-bottom: var(--_titleToProductsPadding);
`
const TitleAndProductDiv = styled.div`
  background-color: #181818;
  display: flex;
  flex-direction: column;
  padding: 6.25rem 0px 6.25rem;
`

const CarouselDiv = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  padding-inline: var(--_paddingLeft);
  gap: var(--_nextItemGap);

  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    white 0%,
    white 80%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, white 0%, white 80%, transparent);
`
const NextControlsDiv = styled.div`
  display: flex;
  /* This is manually aligning to the text div on a product */
  margin-left: calc(
    var(--_paddingLeft) + var(--_imagesWidth) + var(--_imagesToTextGap)
  );
  margin-right: auto;

  @media (width <= ${breakpoints.SMALL}) {
    margin-left: auto;
  }

  @media (width <= ${breakpoints.XSMALL}) {
    margin-left: max(var(--_paddingLeft), auto);
  }
`

const successProducts: Array<SuccessProductType> = [
  {
    images: [
      {
        src: '/mlb-1.png',
        alt: 'MLB standings screenshot',
      },
      {
        src: '/mlb-2.png',
        alt: 'MLB team picker screenshot',
      },
    ],
    description: {
      icon: {
        src: '/logo-mlb.png',
        alt: 'MLB App screenshot',
      },
      appstore: 'https://apps.apple.com/us/app/mlb/id493619333',
      title: 'The #1 app For Live Baseball',
      platforms: ['iOS', 'Android'],
      client: 'MLB',
      techStack: ['SwiftUI', 'WidgetKit', 'Compose', 'GraphQL'],
    },
  },
  {
    images: [
      {
        src: '/videoask-1.png',
        alt: 'Videoask call screenshot',
      },
      {
        src: '/videoask-3.png',
        alt: 'Videoask builder screenshot',
      },
    ],
    description: {
      icon: {
        src: '/logo-videoask.png',
        alt: 'MLB App screenshot',
      },
      appstore:
        'https://apps.apple.com/us/app/videoask-by-typeform/id1438100370',
      title: 'Get face to face with your customers',
      platforms: ['iOS', 'Android', 'Web', 'Backend'],
      client: 'Videoask',
      techStack: [
        'UIKit',
        'CoreData & SwiftData',
        'Android XML',
        'Python',
        'React',
      ],
    },
  },
  {
    images: [
      {
        src: '/privalia-1.png',
        alt: 'Privalia product screenshot',
      },
      {
        src: '/privalia-2.png',
        alt: 'Privalia home screenshot',
      },
    ],
    description: {
      icon: {
        src: '/logo-privalia.png',
        alt: 'Privalia Icon',
      },
      appstore:
        'https://apps.apple.com/es/app/privalia-outlet-de-marcas/id394874573',
      title: 'The biggest online outlet in Brazil',
      platforms: ['iOS', 'Android'],
      client: 'Privalia',
      techStack: ['UIKit', 'Android XML', 'Adyen'],
    },
  },
  {
    images: [
      {
        src: '/naturitas-2.png',
        alt: 'Naturitas products screenshot',
      },
      {
        src: '/naturitas-1.png',
        alt: 'Naturitas checkout screenshot',
      },
    ],
    description: {
      icon: {
        src: '/logo-naturitas.png',
        alt: 'MLB App screenshot',
      },
      appstore:
        'https://apps.apple.com/es/app/naturitas-salud-natural/id1523025531',
      title: 'Your trusted natural products store',
      platforms: ['iOS', 'Android'],
      client: 'Naturitas',
      techStack: ['UIKit', 'SwiftUI', 'Android XML', 'SPM'],
    },
  },
  {
    images: [
      {
        src: '/mediquo-1.png',
        alt: 'Mediquo chat screenshot',
      },
      {
        src: '/mediquo-2.png',
        alt: 'Mediquo subscriptions screenshot',
      },
    ],
    description: {
      icon: {
        src: '/logo-mediquo.png',
        alt: 'Mediquo Logo',
      },
      appstore:
        'https://apps.apple.com/es/app/mediquo-chat-consulta-m%C3%A9dica/id1320968041',
      title: 'Your complete telemedicine platform',
      platforms: ['iOS', 'Android'],
      client: 'MediQuo',
      techStack: ['SwiftUI', 'Android XML', 'SPM'],
    },
  },
]

function convertToPixels(value: string): number {
  if (typeof value !== 'string') {
    throw new Error('Input must be a string')
  }

  const unitMatch = value.match(/[a-zA-Z%]+/)
  const numberMatch = value.match(/[0-9.]+/)

  if (!unitMatch || !numberMatch) {
    throw new Error('Invalid value')
  }

  const unit: string = unitMatch[0]
  const number: number = parseFloat(numberMatch[0])

  if (isNaN(number)) {
    throw new Error('Invalid number value')
  }

  let pixels: number = 0

  switch (unit) {
    case 'px':
      pixels = number
      break
    case 'rem':
      const rootFontSize: number = parseFloat(
        getComputedStyle(document.documentElement).fontSize
      )
      pixels = number * rootFontSize
      break
    default:
      throw new Error('Unsupported unit')
  }

  return pixels
}

const ProductsSection: FunctionComponent = () => {
  const [selectedItem, setSelectedItem] = useState(0)
  const [currentScroll, setCurrentScroll] = useState(0)
  const scrollableDivRef = useRef<HTMLDivElement & Element>(null)
  const { width } = useWindowSize()
  const { t } = useTranslation()

  useEffect(() => {
    setCurrentScroll(0)
    setSelectedItem(0)
  }, [width])

  useEffect(() => {
    scrollableDivRef?.current?.scrollTo({
      top: 0,
      left: currentScroll,
      behavior: 'smooth',
    })
  }, [currentScroll])

  const getPaddingLeft = useCallback((): number => {
    if (!scrollableDivRef.current) {
      return 0
    }
    const padding = getComputedStyle(scrollableDivRef.current).getPropertyValue(
      '--_paddingLeft'
    )
    return convertToPixels(padding)
  }, [scrollableDivRef])

  const onNextClick = useCallback(() => {
    setSelectedItem((value) => (value + 1) % successProducts.length)

    if (scrollableDivRef?.current) {
      const isBigScreen =
        window.innerWidth > convertToPixels(breakpoints.XSMALL)
      const factor = isBigScreen ? 2 : 1
      setCurrentScroll(
        (value) => value + (window.innerWidth - factor * getPaddingLeft())
      )
    }
  }, [scrollableDivRef])

  const onPreviousClick = useCallback(() => {
    setSelectedItem((value) => (value - 1) % successProducts.length)
    if (scrollableDivRef?.current) {
      const isBigScreen =
        window.innerWidth > convertToPixels(breakpoints.XSMALL)
      const factor = isBigScreen ? 2 : 1
      setCurrentScroll(
        (value) => value - (window.innerWidth - factor * getPaddingLeft())
      )
    }
  }, [scrollableDivRef])

  return (
    <FrameParentRoot>
      <TitleAndProductDiv>
        <Title>{t('section.products.title')}</Title>
        <CarouselDiv ref={scrollableDivRef}>
          {successProducts.map((prod, index) => (
            <SuccessProduct key={index} product={prod} />
          ))}
        </CarouselDiv>

        <NextControlsDiv>
          <NextPreviousControls
            isNextEnabled={selectedItem !== successProducts.length - 1}
            isPreviousEnabled={selectedItem !== 0}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
          />
        </NextControlsDiv>
      </TitleAndProductDiv>
    </FrameParentRoot>
  )
}

export default ProductsSection
