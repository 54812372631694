import { FC } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { VIDEOASK_URL } from '../App'
import { useTranslation } from 'react-i18next'

const FreeConsultation = styled.h3`
  margin: 0;
  text-decoration: none;
  position: relative;
  font-size: 21px;
  line-height: 26.5px;
  font-weight: 500;
  font-family: 'Space Grotesk';
  color: #000;
  text-align: left;
  white-space: nowrap;
`
const FreeConsultationWrapper = styled(Link)`
  cursor: pointer;
  border: none;
  padding: 1rem 1.5rem;
  background-color: #fff;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  text-decoration-line: none;

  &:hover {
    background-color: #e6e6e6;
  }

  @media screen and (max-width: 850px) {
    display: none;
  }
`

const FreeConsultationButton: FC = () => {
  const { t } = useTranslation()
  return (
    <FreeConsultationWrapper to={VIDEOASK_URL}>
      <FreeConsultation>{t('header.freeConsultation')}</FreeConsultation>
    </FreeConsultationWrapper>
  )
}

export default FreeConsultationButton
