import { FunctionComponent } from 'react'
import styled from 'styled-components'
import OurMethodSteps from '../components/OurMethodSteps'
import TechStack from '../components/TechStack'
import FooterSection from '../components/Footer'
import { useTranslation } from 'react-i18next'

const OurMethod1 = styled.h1`
  margin: 0;
  align-self: stretch;
  font-size: inherit;
  font-weight: 200;
  font-family: inherit;
  display: inline-block;
  line-height: 1;
  @media screen and (max-width: 750px) {
    font-size: var(--font-size-35xl);
  }
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-13xl);
  }
`
const WeConcentrateOur = styled.p`
  margin: 0;
  font-size: var(--font-size-5xl);
  font-weight: 300;
  font-family: var(--font-sf-compact);
  color: var(--color-gray-400);
  display: inline-block;
  max-width: 40rem;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-lgi);
  }
`
const OurMethodParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-16xl);
  max-width: 100%;
  @media screen and (max-width: 450px) {
    gap: var(--gap-mid);
  }
`
const OurMethodInner = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-89xl);
  color: var(--color-white);
  font-family: var(--font-feature-display);
`
const DividerLine = styled.div`
  height: 0.063rem;
  position: relative;
  background-color: rgba(217, 217, 217, 0.3);
`
const WeAdaptOur = styled.p`
  margin: 0;
  height: 7.25rem;
  position: relative;
  font-size: var(--font-size-5xl);
  font-weight: 300;
  font-family: var(--font-sf-compact);
  color: var(--color-gray-400);
  display: inline-block;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-lgi);
  }
`
const FrameSection = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0rem var(--padding-xl) 3.187rem;
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-89xl);
  color: var(--color-white);
  font-family: var(--font-feature-display);
`
const OurMethodRoot = styled.div`
  --_margin-inline: 7.5rem;

  background-color: var(--color-black);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8.5rem;
  line-height: normal;
  letter-spacing: normal;
  margin: 0 var(--_margin-inline);
  @media screen and (max-width: 1000px) {
    gap: 4.25rem;
    --_margin-inline: 5rem;
  }
  @media screen and (max-width: 450px) {
    gap: 2.125rem;
    --_margin-inline: 2.5rem;
  }
`
const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7.5rem;
`

const OurMethod: FunctionComponent = () => {
  const { t } = useTranslation()
  return (
    <MainDiv>
      <OurMethodRoot>
        <OurMethodInner>
          <OurMethodParent>
            <OurMethod1>{t('section.ourMethod.method.title')}</OurMethod1>
            <WeConcentrateOur>
              {t('section.ourMethod.method.description')}
            </WeConcentrateOur>
          </OurMethodParent>
        </OurMethodInner>
        <OurMethodSteps />
      </OurMethodRoot>
      <DividerLine />
      <OurMethodRoot>
        <FrameSection>
          <OurMethodParent>
            <OurMethod1>{t('section.ourMethod.tech.title')}</OurMethod1>
            <WeConcentrateOur>
              {t('section.ourMethod.tech.description')}
            </WeConcentrateOur>
          </OurMethodParent>
        </FrameSection>
        <TechStack />
      </OurMethodRoot>
      <FooterSection />
    </MainDiv>
  )
}

export default OurMethod
