import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const FromFatFixedCostParent = styled.section`
  background-color: #ff5d5d;
  display: flex;
  flex-direction: column;
  padding: 54px;
  box-sizing: border-box;
  gap: 14px;
  max-width: 100%;
  z-index: 5;
  text-align: left;
  font-size: 282px;
  color: #fff;
  font-family: 'Hubot-Sans';
  font-stretch: condensed;

  @media screen and (max-width: 1000px) {
    font-size: 200px;
    padding: 44px;
  }

  @media screen and (max-width: 850px) {
    font-size: 140px;
    padding: 34px;
  }

  @media screen and (max-width: 450px) {
    font-size: 100px;
    padding: 24px;
  }
`
const FromFatFixedContainer = styled.h1`
  margin: 0;
  max-width: 1350px;
  position: relative;
  font-size: inherit;
  letter-spacing: -0.03em;
  line-height: 82%;
  font-weight: 900;
  font-family: inherit;
  display: inline-block;
`
const ToSlimVariableContainer = styled.h1`
  margin: 0;
  max-width: 1350px;
  font-size: inherit;
  line-height: 88%;
  font-weight: 200;
  font-stretch: condensed;
`

const FromFixedToSlimSection: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <FromFatFixedCostParent>
      <FromFatFixedContainer>
        {t('section.fromFixedToSlim.fromFatFixedCost')}
      </FromFatFixedContainer>
      <ToSlimVariableContainer>
        {t('section.fromFixedToSlim.toSlimVariableCost')}
      </ToSlimVariableContainer>
    </FromFatFixedCostParent>
  )
}

export default FromFixedToSlimSection
