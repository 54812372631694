import { FunctionComponent, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { VIDEOASK_URL } from '../App'
import { useTranslation } from 'react-i18next'

export type FooterSectionType = {
  className?: string
}

export const TLBLink = styled(Link)`
  color: white;
  text-decoration-line: none;
  text-decoration-thickness: 0rem;

  transition: text-decoration-thickness 0.2s;

  &:hover,
  &:focus {
    text-decoration-line: underline;
    text-underline-offset: 6px;
    text-decoration-thickness: 2px;
  }
`

const SvgLogoMobile1Icon = styled.img`
  width: 58px;
  height: 98px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
`
const Language = styled.a`
  text-decoration: none;
  position: relative;
  color: inherit;
  display: inline-block;
  min-width: 91px;
  @media screen and (max-width: 450px) {
    font-size: 17px;
  }
`
const LangBtn = styled.button<{ isSelected: boolean }>`
  text-decoration: underline;
  background: transparent;
  border: transparent;
  font-size: inherit;
  font-weight: inherit;
  color: ${({ isSelected }) => (isSelected ? 'white' : 'inherit')};

  text-decoration-line: none;
  text-decoration-thickness: 0rem;

  transition: text-decoration-thickness 0.2s;

  &:hover,
  &:focus {
    text-decoration-line: underline;
    text-underline-offset: 6px;
    text-decoration-thickness: 2px;
  }

  @media screen and (max-width: 450px) {
    font-size: 17px;
  }
`
const LanguageButtonsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`
const LanguageParent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 20px 0px 0px;
  box-sizing: border-box;
  gap: 32px;
  min-width: 149px;
`
const Contact = styled.div`
  position: relative;
  display: inline-block;
  min-width: 73px;
  @media screen and (max-width: 450px) {
    font-size: 17px;
  }
`
const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`
const ContactParent = styled.div`
  flex: 0.4358;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 121px 0px 0px;
  box-sizing: border-box;
  gap: 32px;
  min-width: 149px;
  @media screen and (max-width: 450px) {
    flex: 1;
  }
`
const Pages = styled.div`
  position: relative;
  display: inline-block;
  min-width: 57px;
  @media screen and (max-width: 450px) {
    font-size: 17px;
  }
`
const PagesParent = styled.div`
  flex: 0.6089;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 90px 0px 0px;
  box-sizing: border-box;
  gap: 32px;
  min-width: 149px;
  @media screen and (max-width: 450px) {
    flex: 1;
  }
`
const FrameGroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  @media screen and (max-width: 850px) {
    flex-wrap: wrap;
  }
`
const TheLeftBit = styled.div``
const TheLeftBitParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
`
const WeDontUse = styled.div``
const PrivacyAndLegal = styled(TLBLink)`
  color: inherit;
`
const FrameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 64px;
  font-size: 18px;
  @media screen and (max-width: 850px) {
    flex-wrap: wrap;
    gap: 32px;
  }
  @media screen and (max-width: 450px) {
    gap: 16px;
  }
`
const SvgLogoMobile1Parent = styled.footer`
  flex: 1;
  background-color: #000;
  display: flex;
  flex-direction: column;
  padding: 64px 281px 64px 64px;
  gap: 184px;
  min-width: 633px;
  max-width: 100%;
  text-align: left;
  font-size: 21px;
  color: #656565;
  font-family: 'SF Compact';
  @media screen and (max-width: 1225px) {
    gap: 92px;
    padding-left: 32px;
    padding-right: 140px;
  }
  @media screen and (max-width: 850px) {
    gap: 46px;
    padding-top: 42px;
    padding-right: 70px;
    padding-bottom: 42px;
    min-width: 100%;
  }
  @media screen and (max-width: 450px) {
    padding-top: 27px;
    padding-bottom: 27px;
  }
`
const Div1 = styled.div`
  position: relative;
  display: inline-block;
  min-width: 25px;
  @media screen and (max-width: 450px) {
    font-size: 17px;
  }
`
const DoYouHave = styled.p`
  margin: 0;
  color: #222;
  @media screen and (max-width: 450px) {
    font-size: 17px;
  }
`
const WereHappyTo = styled.p`
  margin: 0;
  color: #b0b0b0;
  @media screen and (max-width: 450px) {
    font-size: 17px;
  }
`
const WereHappyToContainer = styled.div`
  margin: 0;
`
const Parent1 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media screen and (max-width: 450px) {
    gap: 16px;
  }
`
const LetsTalk = styled.h3`
  margin: 0;
  font-size: 2rem;
  font-weight: 400;
  width: fit-content;
  margin: auto;
`
const LetsTalkWrapper = styled(Link)`
  width: min(30rem, 100%);
  border-radius: 100vw;
  background: #fff;
  color: #444;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  text-decoration-line: none;
  padding-block: 46px;

  &:hover {
    background-color: #eee;
  }
`
const FrameDiv = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 289px 150px 112px 64px;
  box-sizing: border-box;
  gap: 64px;
  min-width: 538px;
  max-width: 100%;
  @media screen and (max-width: 1225px) {
    flex: 1;
  }
  @media screen and (max-width: 850px) {
    gap: 32px;
    padding: 188px 75px 73px 32px;
    min-width: 100%;
  }
  @media screen and (max-width: 450px) {
    gap: 16px;
    padding-top: 122px;
    padding-right: 20px;
    padding-bottom: 47px;
  }
`
const FrameParentRoot = styled.section`
  display: flex;
  flex-direction: row;
  row-gap: 20px;
  max-width: 100%;
  z-index: 7;
  font-size: 21px;
  color: #d4d4d4;
  @media screen and (max-width: 1225px) {
    flex-wrap: wrap;
  }
`

const FooterSection: FunctionComponent<FooterSectionType> = () => {
  const { t, i18n } = useTranslation()

  const changeLanguage = useCallback(
    (lang: string) => {
      i18n.changeLanguage(lang)
    },
    [i18n]
  )
  return (
    <FrameParentRoot>
      <SvgLogoMobile1Parent>
        <Link to={'/'}>
          <SvgLogoMobile1Icon
            loading="lazy"
            alt=""
            src="/svglogomobile-1.svg"
          />
        </Link>
        <FrameGroup>
          <LanguageParent>
            <Language>{t('language')}</Language>
            <LanguageButtonsDiv>
              <LangBtn
                isSelected={i18n.language === 'en'}
                onClick={() => {
                  changeLanguage('en')
                }}
              >
                EN
              </LangBtn>
              <LangBtn
                isSelected={i18n.language === 'es'}
                onClick={() => {
                  changeLanguage('es')
                }}
              >
                ES
              </LangBtn>
              <LangBtn
                isSelected={i18n.language === 'ca'}
                onClick={() => {
                  changeLanguage('ca')
                }}
              >
                CA
              </LangBtn>
            </LanguageButtonsDiv>
          </LanguageParent>
          <ContactParent>
            <Contact>{t('footer.contact')}</Contact>
            <LinksWrapper>
              <TLBLink to={'mailto:hola@theleftbit.com'}>
                {t('footer.mail')}
              </TLBLink>
              <TLBLink
                to={'https://es.linkedin.com/company/theleftbit'}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('footer.linkedin')}
              </TLBLink>
              <TLBLink
                to={'https://x.com/TheLeftBit'}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('footer.twitter')}
              </TLBLink>
            </LinksWrapper>
          </ContactParent>
          <PagesParent>
            <Pages>{t('pages')}</Pages>
            <LinksWrapper>
              <TLBLink to={'/team'}>{t('header.theTeam')}</TLBLink>
              <TLBLink to={'/our-method'}>{t('header.ourMethod')}</TLBLink>
            </LinksWrapper>
          </PagesParent>
        </FrameGroup>
        <FrameContainer>
          <TheLeftBitParent>
            <TheLeftBit>{t('footer.trademark')}</TheLeftBit>
          </TheLeftBitParent>
          <WeDontUse>{t('footer.dontusecookies')}</WeDontUse>
          <PrivacyAndLegal
            to={
              'https://raw.githubusercontent.com/theleftbit/PrivacyPolicy/main/lazy_weather_privacy_policy.md'
            }
          >
            {t('footer.privacyAndLegal')}
          </PrivacyAndLegal>
        </FrameContainer>
      </SvgLogoMobile1Parent>
      <FrameDiv>
        <Parent1>
          <Div1>􀇯</Div1>
          <DoYouHave>{t('footer.doYouHaveAnIdea')}</DoYouHave>
          <WereHappyToContainer>
            <WereHappyTo>{t('footer.happyToTalk')}</WereHappyTo>
            <WereHappyTo>{t('footer.developIt')}</WereHappyTo>
          </WereHappyToContainer>
        </Parent1>
        <LetsTalkWrapper to={VIDEOASK_URL}>
          <LetsTalk>{t('footer.letsTalk')}</LetsTalk>
        </LetsTalkWrapper>
      </FrameDiv>
    </FrameParentRoot>
  )
}

export default FooterSection
