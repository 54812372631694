import { FunctionComponent } from 'react'
import styled from 'styled-components'
import Testimonial from '../../components/Testimonial'
import { useTranslation } from 'react-i18next'

export type ClientsQuotesType = {
  className?: string
}

const InOurClients = styled.div`
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  @media screen and (max-width: 450px) {
    font-size: 18px;
  }
`
const InOurClientsWordsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  max-width: 100%;
  padding-left: 120px;

  @media screen and (max-width: 850px) {
    padding-left: 80px;
  }
  @media screen and (max-width: 450px) {
    padding-left: 40px;
  }
`
const FrameParent = styled.div`
  overflow-x: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 20px 120px;
  box-sizing: border-box;
  gap: 99px;
  max-width: 100%;
  @media screen and (max-width: 850px) {
    gap: 49px;
    padding-left: 80px;
  }
  @media screen and (max-width: 450px) {
    gap: 25px;
    padding-left: 40px;
  }
`
const ScrollWrapper = styled.div`
  align-self: stretch;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 74px 0px;
  box-sizing: border-box;
  min-height: 522px;
  max-width: 100%;
  font-size: 25px;
  color: #222;
  font-family: 'Space Grotesk';
`
const ClientsQuotesRoot = styled.section`
  align-self: stretch;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 152px 0px 0px 0px;
  box-sizing: border-box;
  gap: 88px;
  max-width: 100%;
  z-index: 1;
  text-align: left;
  font-size: 22px;
  color: #b1b1b1;
  font-family: 'SF Compact';
  @media screen and (max-width: 850px) {
    gap: 44px;
    padding-top: 99px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 450px) {
    gap: 22px;
    padding-top: 64px;
    box-sizing: border-box;
  }
`

const ClientsQuotes: FunctionComponent<ClientsQuotesType> = ({
  className = '',
}) => {
  const { t } = useTranslation()
  return (
    <ClientsQuotesRoot className={className}>
      <InOurClientsWordsWrapper>
        <InOurClients>{t('section.quotes.title')}</InOurClients>
      </InOurClientsWordsWrapper>
      <ScrollWrapper>
        <FrameParent>
          <Testimonial
            ellipse1="/ellipse-1@2x.png"
            davidOkuniev="David Okuniev"
            typeformCoFounder="Typeform co-founder"
            prop="/quotes.svg"
            theLeftBitTeamNotOnlyHelp="The Left Bit team not only helped us launch the app in a fraction of time, but was also involved during the evolution of it."
          />
          <Testimonial
            ellipse1="/ellipse-1-1@2x.png"
            davidOkuniev="Dave Lewanda"
            typeformCoFounder="Director of Software Engineering at MLB"
            prop="/quotes.svg"
            theLeftBitTeamNotOnlyHelp="The Left Bit team really knows their stuff when it comes to mobile development. I'd be thrilled to work with them again!"
          />
          <Testimonial
            ellipse1="/ellipse-1-2@2x.png"
            davidOkuniev="Rodrigo López"
            typeformCoFounder="PMO at Naturitas"
            prop="/quotes.svg"
            theLeftBitTeamNotOnlyHelp="The Left Bit team has helped us create applications that have
            contributed, without a doubt, to the exponential growth that we
            have experienced."
          />
        </FrameParent>
      </ScrollWrapper>
    </ClientsQuotesRoot>
  )
}

export default ClientsQuotes
