import { FC } from 'react'
import styled from 'styled-components'

const RootDiv = styled.div`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`
const StepsDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 600px;
  padding: 0 3rem;
`

const Step = styled.div`
  --_factor: 1;
  display: flex;
  gap: calc(5rem * var(--_factor));

  @media screen and (max-width: 600px) {
    --_factor: 0.6;
  }
`

const BallImage = styled.img<{ $size: number }>`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  height: calc(var(--_factor) * ${({ $size }) => $size}rem);
`

const StepTextBox = styled.div<{ $size: number }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  color: var(--color-gray-400);
  font-family: 'SF Compact';
  gap: 1rem;
  padding-top: calc(var(--_factor) * ${({ $size }) => 0.2 * $size}rem);

  @media screen and (max-width: 450px) {
    padding-top: 0;
  }
`

const StepTitle = styled.h3`
  margin: 0;
  font-weight: 600;
`
const StepDescription = styled.p`
  margin: 0;
  font-weight: 200;
`
const Line = styled.div`
  inset: 0;
  margin: auto;
  height: 100%;
  width: 1px;
  background: white;
  opacity: 0.5;
  height: 100px;
`
const BallAndVerticalLine = styled.div`
  display: flex;
  width: 5rem;
  flex-direction: column;
  gap: 1rem;
`

const BallWrapper = styled.div`
  position: relative;
  padding-top: 0.5rem;
`

const stepData = [
  {
    image: {
      src: '/bola1.svg',
      height: 2.5,
    },
    title: 'MVP',
    description: 'Small value proposition to test in the market',
  },
  {
    image: {
      src: '/bola2.svg',
      height: 4.5,
    },
    title: 'FIRST ITERATION',
    description: 'When market fit & user value is proven',
  },
  {
    image: {
      src: '/bola3.svg',
      height: 10,
    },
    title: 'TESTED PRODUCT',
    description: 'With all necessary features for the market',
  },
]

const OurMethodSteps: FC = () => {
  return (
    <RootDiv>
      <StepsDiv>
        {stepData.map((step, index) => (
          <Step key={index}>
            <BallAndVerticalLine>
              <BallWrapper>
                <BallImage src={step.image.src} $size={step.image.height} />
              </BallWrapper>
              {index != stepData.length - 1 && <Line />}
            </BallAndVerticalLine>
            <StepTextBox $size={step.image.height}>
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </StepTextBox>
          </Step>
        ))}
      </StepsDiv>
    </RootDiv>
  )
}

export default OurMethodSteps
