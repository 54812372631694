import { FC, useRef, useState } from 'react'
import styled from 'styled-components'
import SegmentedControl from '../../components/SegmentedControl'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { VIDEOASK_URL } from '../../App'
import CallToActionButton from '../../components/CallToActionButton'

const MakeYourBusiness = styled.h2`
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
  @media screen and (max-width: 850px) {
    font-size: 29px;
  }
  @media screen and (max-width: 450px) {
    font-size: 22px;
    line-height: 32px;
  }
`
const WeWillHelp = styled.div`
  font-size: 24px;
  font-weight: 300;
  font-family: 'SF Compact';
  color: rgba(31, 30, 92, 0.7);

  @media screen and (max-width: 450px) {
    font-size: 19px;
  }
`
const TitleSubtitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`
const MvpStart = styled.div`
  font-size: 21px;
  font-weight: 500;
  font-family: 'SF Compact';
  color: #fff;
  text-align: center;
  display: inline-block;
  min-width: 90px;

  @media screen and (max-width: 450px) {
    font-size: 16px;
  }
`
const Buttonthingie = styled.button`
  cursor: pointer;
  border: none;
  padding: 16px;
  background-color: #6360ff;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
  &:hover {
    background-color: #7d7aff;
  }

  @media screen and (max-width: 450px) {
    padding: 8px;
  }
`
const OngoingRelationship = styled.div`
  position: relative;
  font-weight: 500;
  @media screen and (max-width: 450px) {
    font-size: 17px;
  }
`
const ButtonthingieParent = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(99, 96, 255, 0.1);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 2px 23px 2px 3px;
  gap: 24px;
  max-width: 100%;
`
const SegmentedControllerDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  font-size: 21px;
  color: rgba(99, 96, 255, 0.7);
  font-family: 'SF Compact';
`
const FigureB = styled.b`
  @media screen and (max-width: 850px) {
    font-size: 48px;
  }
  @media screen and (max-width: 450px) {
    font-size: 36px;
  }
`
const UnitH1 = styled.h1`
  margin: 0;
  font-size: 42px;
  font-weight: 400;
  font-family: inherit;
  min-width: 29px;

  color: rgba(31, 30, 92, 0.18);

  @media screen and (max-width: 850px) {
    font-size: 34px;
  }
  @media screen and (max-width: 450px) {
    font-size: 25px;
  }
`
const FigureAndUnitDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
  gap: 5px;
`
const FigureDescriptionDiv = styled.div`
  position: relative;
  font-size: 21px;
  font-weight: 300;
  font-family: 'SF Compact';
  color: rgba(31, 30, 92, 0.7);
  @media screen and (max-width: 450px) {
    font-size: 17px;
  }
`
const FigureAndTitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: 32px;

  @media screen and (max-width: 850px) {
    align-items: baseline;
    gap: 0px;
    padding-left: 16px;
  }
  @media screen and (max-width: 450px) {
    box-sizing: border-box;
  }
`
const FiguresDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 106px;
  font-size: 80px;

  @media screen and (max-width: 850px) {
    flex-direction: column;
    gap: 40px;
  }

  > * {
    flex: 1;
  }
`
const FrameParentRoot = styled.section`
  background-color: #e9e9ff;
  display: flex;
  flex-direction: column;
  padding: 120px;
  gap: 136px;
  text-align: left;
  font-size: 36px;
  color: #1f1e5c;
  font-family: 'Space Grotesk';

  @media screen and (max-width: 1000px) {
    gap: 80px;
    padding: 80px;
  }

  @media screen and (max-width: 850px) {
    gap: 60px;
    padding: 60px;
  }

  @media screen and (max-width: 450px) {
    gap: 30px;
    padding: 20px;
  }
`
const MakeBusinessWithUsSection: FC = () => {
  const { t } = useTranslation()
  const [selectedValue1, setSelectedValue] = useState(0)

  const data = [
    [
      {
        figure: '15k',
        unit: '€',
        description: t('section.makeBusiness.mvpFigure1'),
      },
      {
        figure: '2',
        unit: 'months',
        description: t('section.makeBusiness.mvpFigure2'),
      },
      {
        figure: '100',
        unit: '%',
        description: t('section.makeBusiness.mvpFigure3'),
      },
    ],
    [
      {
        figure: '30',
        unit: '%',
        description: t('section.makeBusiness.ongoingFigure1'),
      },
      {
        figure: '2',
        unit: 'weeks',
        description: t('section.makeBusiness.ongoingFigure2'),
      },
      {
        figure: '99.98',
        unit: '%',
        description: t('section.makeBusiness.ongoingFigure3'),
      },
    ],
  ]

  return (
    <FrameParentRoot>
      <TitleSubtitleDiv>
        <MakeYourBusiness>
          {t('section.makeBusiness.makeYourBusiness')}
        </MakeYourBusiness>
        <WeWillHelp>{t('section.makeBusiness.weWillHelp')}</WeWillHelp>
      </TitleSubtitleDiv>
      <SegmentedControllerDiv>
        <SegmentedControl
          name="group-1"
          callback={(val: number) => setSelectedValue(val)}
          controlRef={useRef()}
          segments={[
            {
              label: t('section.makeBusiness.mvpStart'),
              value: 0,
              ref: useRef(),
            },
            {
              label: t('section.makeBusiness.ongoingRelationship'),
              value: 1,
              ref: useRef(),
            },
          ]}
        />
      </SegmentedControllerDiv>
      <FiguresDiv>
        {data[selectedValue1].map((elem, index) => (
          <FigureAndTitleDiv key={index}>
            <FigureAndUnitDiv>
              <FigureB>{elem.figure}</FigureB>
              <UnitH1>{elem.unit}</UnitH1>
            </FigureAndUnitDiv>
            <FigureDescriptionDiv>{elem.description}</FigureDescriptionDiv>
          </FigureAndTitleDiv>
        ))}
      </FiguresDiv>
      <CallToActionButton
        title={t('section.makeBusiness.bookAFreeConsultation')}
      />
    </FrameParentRoot>
  )
}

export default MakeBusinessWithUsSection
