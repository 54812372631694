import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ImageWithNotch from './FramedImage'
import { breakpoints } from '../pages/sections/OpeningSection'

export interface ImgType {
  src: string
  alt?: string
}

export interface SuccessProductType {
  images: Array<ImgType>
  description: SuccessProductDescriptionType
}

export interface SuccessProductDescriptionType {
  icon: ImgType
  appstore: string
  title: string
  platforms: Array<string>
  client: string
  techStack: Array<string>
}

interface SuccessProductBlockType {
  product: SuccessProductType
}

const ImagesAndDescriptionDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--_imagesToTextGap);

  @media (width <= ${breakpoints.XSMALL}) {
    flex-direction: column;
  }
`

const TextDiv = styled.div`
  width: calc(
    100vw - var(--_imagesWidth) - var(--_imagesToTextGap) -
      (2 * var(--_paddingLeft))
  );

  @media (width <= ${breakpoints.XSMALL}) {
    width: calc(100vw - (2 * var(--_paddingLeft)));
  }
`

const ImagesDiv = styled.div`
  position: relative;
  width: 15.625rem;
  height: 31.25rem;
`
const ImagePositioned = styled.div<{ pos: number }>`
  position: absolute;
  width: 12.5rem;
  --_separation: 3.125rem;
  --_index: ${({ pos }) => pos};
  left: calc(var(--_index) * var(--_separation));
  top: calc(var(--_index) * var(--_separation));
  z-index: ${({ pos }) => pos};
`

const SuccessProductBlock: FC<SuccessProductBlockType> = ({ product }) => {
  return (
    <ImagesAndDescriptionDiv>
      <ImagesDiv>
        {product.images.map((img, index) => (
          <ImagePositioned key={index} pos={index}>
            <ImageWithNotch src={img.src} />
          </ImagePositioned>
        ))}
      </ImagesDiv>
      <TextDiv>
        <TextBlock {...product.description} />
      </TextDiv>
    </ImagesAndDescriptionDiv>
  )
}

const FrameContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 30px;
  color: #b4b4b4;
`
const IconAndTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--_productTitleToTextGap);
  height: 100%;
`

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 31px;
  flex-shrink: 0;
  font-family: 'SF Compact';
  @media screen and (max-width: 450px) {
    gap: 15px;
  }
`
const ItemTitleB = styled.b`
  text-align: left;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
`
const ItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  font-size: 18px;
  color: #b4b4b4;
`
const PlatformsParent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const WorksParent = styled(PlatformsParent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const FrameParent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 56px;
`
const FrameDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 64px;
  font-size: 16px;
  color: #414141;
  height: 100%;
`
const ProductIconButton = styled(Link)`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  cursor: pointer;
  background: transparent;

  &:hover,
  &:focus {
    opacity: 0.5;
  }
`
const ProductIcon = styled.img`
  width: 64px;
  height: 64px;
  object-fit: contain;
  display: block;
  border-radius: 15px;
`
const ProductTitleH3 = styled.h3`
  margin: 0;
  position: relative;
  font-size: inherit;
  text-transform: capitalize;
  font-weight: 400;
  font-family: inherit;
  @media screen and (max-width: 850px) {
    font-size: 24px;
  }
  @media screen and (max-width: 450px) {
    font-size: 18px;
  }
`
const TitleH3 = styled.h3`
  margin: 0;
`
const IconAndClientDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
`

const TextBlock: FC<SuccessProductDescriptionType> = ({
  icon,
  title,
  appstore,
  client,
  platforms,
  techStack,
}) => {
  const { t } = useTranslation('product')

  return (
    <FrameContainer>
      <IconAndTextDiv>
        <IconWrapper>
          <IconAndClientDiv>
            <ProductIconButton
              to={appstore}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ProductIcon alt={icon.alt} src={icon.src} />
            </ProductIconButton>
            <TitleH3>{client}</TitleH3>
          </IconAndClientDiv>
          <ProductTitleH3>{title}</ProductTitleH3>
        </IconWrapper>
        <FrameDiv>
          <FrameParent>
            <PlatformsParent>
              <ItemTitleB>{t('platforms')}</ItemTitleB>
              {platforms.map((platform, index) => (
                <ItemDiv key={index}>{platform}</ItemDiv>
              ))}
            </PlatformsParent>
            <WorksParent>
              <ItemTitleB>{t('techStack')}</ItemTitleB>
              {techStack.map((tech, index) => (
                <ItemDiv key={index}>{tech}</ItemDiv>
              ))}
            </WorksParent>
          </FrameParent>
        </FrameDiv>
      </IconAndTextDiv>
    </FrameContainer>
  )
}

export default SuccessProductBlock
