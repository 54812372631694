import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const FrameParent = styled.section`
  display: flex;
  flex-direction: column;
  padding: 152px 120px;
  gap: 64px;
  color: rgba(233, 233, 255, 0.2);
  font-family: 'SF Pro';

  @media screen and (max-width: 660px) {
    padding: 80px 40px;
  }
`
const WeThinkParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 156px;
  color: #e9e9ff;
  font-family: 'Hubot-Sans';

  @media screen and (max-width: 1000px) {
    font-size: 116px;
  }

  @media screen and (max-width: 810px) {
    font-size: 84px;
  }

  @media screen and (max-width: 660px) {
    font-size: 52px;
  }
`
const WeThink = styled.b`
  letter-spacing: -0.03em;
  line-height: 100%;
  font-weight: 700;
`
const WeHelpYou = styled.p`
  font-size: 20px;
  font-family: 'SF Compact';
  color: #c2c2c2;
  font-size: 20px;

  @media screen and (max-width: 660px) {
    font-size: 18px;
  }
`
interface TitleExplainType {
  symbol: string
  title: string
  message: string
}

const RootExplain = styled.div`
  display: flex;
  flex-direction: column;
`
const Parent1 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 42px;

  @media screen and (max-width: 660px) {
    gap: 34px;
  }
`
const H = styled.h1`
  margin: 0;
  font-size: 44px;
  line-height: 100%;
  font-weight: 800;
  color: rgba(255, 255, 255, 0.5);
`

const TitleExplain: FC<{ item: TitleExplainType }> = ({
  item: { symbol, title, message },
}) => {
  return (
    <RootExplain>
      <Parent1>
        <H>{symbol}</H>
        <WeThinkParent>
          <WeThink>{title}</WeThink>
        </WeThinkParent>
      </Parent1>
      <WeHelpYou>{message}</WeHelpYou>
    </RootExplain>
  )
}

const WeThinkSection: FC = () => {
  const { t } = useTranslation()

  const data: Array<TitleExplainType> = [
    {
      symbol: '\u{1001F1}',
      title: t('section.weThink.title1'),
      message: t('section.weThink.message1'),
    },
    {
      symbol: '\u{10021F}',
      title: t('section.weThink.title2'),
      message: t('section.weThink.message2'),
    },
    {
      symbol: '\u{100E13}',
      title: t('section.weThink.title3'),
      message: t('section.weThink.message3'),
    },
  ]

  return (
    <FrameParent>
      {data.map((item, index) => (
        <TitleExplain key={index} item={item} />
      ))}
    </FrameParent>
  )
}

export default WeThinkSection
