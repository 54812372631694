import { FC } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { VIDEOASK_URL } from '../App'

const BookAFreeConsultationButton = styled(Link)`
  border-radius: 112px;
  text-decoration: none;
  background-color: #6360ff;
  align-self: flex-start;
  padding: 48px 56px;
  font-size: 2.2rem;
  color: #fff;
  font-family: 'SF Compact';
  border-width: 0px;
  cursor: pointer;

  &:hover {
    background-color: #7d7aff;
  }

  @media screen and (max-width: 850px) {
    padding: 32px;
  }

  @media screen and (max-width: 450px) {
    padding: 24px;
  }
`
const BookAFree = styled.h3`
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: inherit;

  @media screen and (max-width: 650px) {
    flex-direction: column;
    font-size: 24px;
  }

  @media screen and (max-width: 450px) {
    flex-direction: column;
    font-size: 18px;
  }
`

const CallToActionButton: FC<{ title: string }> = ({ title }) => {
  return (
    <BookAFreeConsultationButton to={VIDEOASK_URL}>
      <BookAFree>{title}</BookAFree>
    </BookAFreeConsultationButton>
  )
}

export default CallToActionButton
