import { FunctionComponent } from 'react'
import styled from 'styled-components'
import TrustedBySection from './sections/TrustedBySection'
import ProductsSection from './sections/ProductsSection'
import ClientsQuotesSection from './sections/ClientsQuotesSection'
import WeThinkSection from './sections/WeThinkSection'
import MakeBusinessWithUsSection from './sections/MakeBusinessWithUsSection'
import FooterSection from '../components/Footer'
import AvoidMistakesSection from './sections/AvoidMistakesSection'
import FromFixedToSlimSection from './sections/FromFixedToSlimSection'
import WeDontWantClientsSection from './sections/WeDontWantClientsSection'
import OpeningSection from './sections/OpeningSection'

const HomeRoot = styled.div`
  background-color: #000;
  overflow-x: hidden;
`

const Home: FunctionComponent = () => {
  return (
    <HomeRoot>
      <OpeningSection />
      <TrustedBySection />
      <ProductsSection />
      <ClientsQuotesSection />
      <WeDontWantClientsSection />
      <WeThinkSection />
      <AvoidMistakesSection />
      <FromFixedToSlimSection />
      <MakeBusinessWithUsSection />
      <FooterSection />
    </HomeRoot>
  )
}

export default Home
