import { FunctionComponent } from 'react'
import styled from 'styled-components'
import FrameComponent2 from './sections/TeamCircles'
import FrameComponent1 from './sections/FrameComponent1'
import FooterSection from '../components/Footer'
import { Link } from 'react-router-dom'
import { VIDEOASK_URL } from '../App'
import { useTranslation } from 'react-i18next'
import CallToActionButton from '../components/CallToActionButton'

const OurTeam = styled.h1`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-weight: 200;
  font-family: inherit;
  display: inline-block;
  @media screen and (max-width: 90px) {
    font-size: 3.375rem;
  }
  @media screen and (max-width: 450px) {
    font-size: 2rem;
  }
`
const WeOnlyWork = styled.span`
  display: block;
`
const WeOnlyWorkContainer = styled.p`
  margin: 0;
  width: 39.188rem;
  position: relative;
  font-size: 1.5rem;
  font-weight: 300;
  font-family: 'SF Compact';
  color: rgba(255, 255, 255, 0.7);
  display: inline-block;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    font-size: 1.188rem;
  }
`
const OurTeamParent = styled.div`
  width: 52.875rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2.187rem;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    gap: 1.063rem;
  }
`
const TeamInner = styled.section`
  width: 67.875rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0rem 1.25rem;
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: 6.75rem;
  color: #fff;
  font-family: 'Feature Display';
`
const TeamChild = styled.div`
  align-self: stretch;
  height: 0.063rem;
  position: relative;
  background-color: rgba(217, 217, 217, 0.3);
`
const WereAlwaysHappy = styled.p`
  margin: 0;
  width: 39.188rem;
  height: 5.438rem;
  position: relative;
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-weight: 300;
  font-family: 'SF Compact';
  color: rgba(255, 255, 255, 0.7);
  display: inline-block;
  min-height: 5.438rem;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    font-size: 1.188rem;
  }
`
const WantToJoinTalkParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2.187rem;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    gap: 1.063rem;
  }
`
const FrameParent = styled.div`
  width: 52.875rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3.75rem;
  max-width: 100%;
  @media screen and (max-width: 1050px) {
    gap: 1.875rem;
  }
`
const FrameSection = styled.section`
  width: 67.875rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0rem 1.25rem 6.5rem;
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: 6.75rem;
  color: #fff;
  font-family: 'Feature Display';
  @media screen and (max-width: 1200px) {
    padding-bottom: 4.25rem;
    box-sizing: border-box;
  }
  @media screen and (max-width: 750px) {
    padding-bottom: 2.75rem;
    box-sizing: border-box;
  }
`
const TeamRoot = styled.div`
  width: 100%;
  position: relative;
  background-color: #000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7.5rem;
  line-height: normal;
  letter-spacing: normal;
  @media screen and (max-width: 750px) {
    gap: 3.75rem;
  }
  @media screen and (max-width: 450px) {
    gap: 1.875rem;
  }
`

const LetsTalk = styled.h3`
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 400;
  font-family: 'SF Compact';
`
const LetsTalkWrapper = styled(Link)`
  width: min(30rem, 100%);
  margin-top: 4rem;
  border-radius: 112px;
  background-color: #111;
  border: 1px solid #555;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 46px 20px;
  white-space: nowrap;
  font-size: 32px;
  color: #555;
  cursor: pointer;
  text-decoration-line: none;

  &:hover {
    background-color: #444;
    color: #aaa;
  }
`

const Team: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <TeamRoot>
      <TeamInner>
        <OurTeamParent>
          <OurTeam>{t('section.team.ourTeam')}</OurTeam>
          <WeOnlyWorkContainer>
            <WeOnlyWork>
              {t('section.team.unparalleledProfessionals')}
            </WeOnlyWork>
            <WeOnlyWork>
              {t('section.team.careAboutBusinessTechDesign')}
            </WeOnlyWork>
          </WeOnlyWorkContainer>
        </OurTeamParent>
      </TeamInner>
      <FrameComponent2 />
      <TeamChild />
      <FrameSection>
        <FrameParent>
          <WantToJoinTalkParent>
            <OurTeam>{t('section.team.wantToJoinTalk')}</OurTeam>
            <WereAlwaysHappy>
              {t('section.team.alwaysHappyWorking')}
            </WereAlwaysHappy>
            <CallToActionButton
              title={t('section.makeBusiness.bookAFreeConsultation')}
            />
          </WantToJoinTalkParent>
        </FrameParent>
      </FrameSection>
      <FooterSection />
    </TeamRoot>
  )
}

export default Team
