import { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { breakpoints } from './OpeningSection'

const TrustedBy = styled.b`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 22px;
  font-family: 'SF Compact';
  color: #b1b1b1;
  padding-left: 120px;

  @media (width <= ${breakpoints.SMALL}) {
    padding-left: 40px;
  }
`

const ClientLogo = styled.img`
  height: ${({ height }) => height}px;
`

const Logos = styled.div`
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 80px;

  width: max-content;
  flex-wrap: nowrap;
`
const ScrollWrapper = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: row;

  &[data-animated='true'] {
    -webkit-mask: linear-gradient(
      90deg,
      transparent,
      white 20%,
      white 80%,
      transparent
    );
    mask: linear-gradient(
      90deg,
      transparent,
      white 20%,
      white 80%,
      transparent
    );

    > * {
      animation: scroll var(--_animation-duration, 40s)
        var(--_animation-direction, forwards) linear infinite;
    }
  }

  @keyframes scroll {
    to {
      transform: translate(calc(-50% - 40px));
    }
  }
`

const RectangleParentRoot = styled.section`
  --_padding-top: 7.5rem;

  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  padding: var(--_padding-top) 0px 154px;
  gap: 64px;

  @media screen and (width > ${breakpoints.SMALL}) {
    --_padding-top: 9rem;
  }
`

const TrustedBySection: FunctionComponent = () => {
  const [isAnimated, setIsAnimated] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setIsAnimated(
      !window.matchMedia('(prefers-reduced-motion: reduce)').matches
    )
  }, [])

  const clients = [
    {
      name: 'Privalia',
      logo: '/privalia.svg',
      height: 39,
    },
    {
      name: 'Typeform',
      logo: '/typeform.svg',
      height: 32,
    },
    {
      name: 'Videoask',
      logo: '/frame@2x.png',
      height: 33,
    },
    {
      name: 'Wallapop',
      logo: '/frame-1.svg',
      height: 43,
    },
    {
      name: 'MLB',
      logo: '/image-1@2x.png',
      height: 43,
    },
    {
      name: 'Naturitas',
      logo: '/naturitas.svg',
      height: 22,
    },
    {
      name: 'Clinic',
      logo: '/Clinic.svg',
      height: 59,
    },
    {
      name: 'Letgo',
      logo: '/Letgo.svg',
      height: 57,
    },
  ]

  return (
    <RectangleParentRoot>
      <TrustedBy>{t('section.trustedBy.title')}</TrustedBy>
      <ScrollWrapper data-animated={isAnimated}>
        <Logos>
          {clients.map((element, index) => (
            <ClientLogo
              key={index}
              alt={element.name}
              src={element.logo}
              height={element.height}
              aria-hidden="false"
            />
          ))}
          {clients.map((element, index) => (
            <ClientLogo
              key={index}
              alt={element.name}
              src={element.logo}
              height={element.height}
              aria-hidden="true"
            />
          ))}
        </Logos>
      </ScrollWrapper>
    </RectangleParentRoot>
  )
}

export default TrustedBySection
