import { FC, Suspense, useEffect } from 'react'
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  useSearchParams,
} from 'react-router-dom'
import Home from './pages/Home'
import Team from './pages/Team'
import styled, { keyframes } from 'styled-components'
import OurMethod from './pages/OurMethod'
import WebsiteHeader from './components/WebsiteHeader'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import Spinner from './components/Spinner'

export const VIDEOASK_URL = 'https://www.videoask.com/fagttujhs'

const HomeRoot = styled.div`
  background-color: #000;
`

function App() {
  const action = useNavigationType()
  const location = useLocation()
  const pathname = location.pathname
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const queryLang = searchParams.get('lang')

    const ALL_LANGUAGES_AVAILABLE = ['en', 'es', 'ca']
    if (queryLang && ALL_LANGUAGES_AVAILABLE.includes(queryLang)) {
      i18n.changeLanguage(queryLang)
    } else {
      i18n.changeLanguage('en')
    }
  }, [searchParams])

  useEffect(() => {
    if (action !== 'POP') {
      window.scrollTo(0, 0)
    }
  }, [action, pathname])

  useEffect(() => {
    let title = ''
    let metaDescription = ''

    switch (pathname) {
      case '/':
        title = ''
        metaDescription = ''
        break
    }

    if (title) {
      document.title = title
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      )
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription
      }
    }
  }, [pathname])

  return (
    <Suspense fallback={<Spinner />}>
      <I18nextProvider i18n={i18n}>
        <HomeRoot>
          <WebsiteHeader />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/our-method" element={<OurMethod />} />
            <Route path="/team" element={<Team />} />
          </Routes>
        </HomeRoot>
      </I18nextProvider>
    </Suspense>
  )
}
export default App
