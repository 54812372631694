import { FC } from 'react'
import styled, { createGlobalStyle } from 'styled-components'

type ImageWithNotchType = {
  src: string
}

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  img, svg {
    display: block;
  }
`

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
`
const ScreenshotImage = styled.img`
  display: block;
  max-width: 100%;
  border-radius: 1.5rem;
  border: 3px solid #333333;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
`

const Notch = styled.div`
  background: black;
  position: absolute;
  top: 1.5%;
  left: 0;
  right: 0;
  width: 31.25%;
  aspect-ratio: calc(125 / 37);
  border-radius: 100vw;
  margin: auto;
  z-index: 2;
`

const ImageWithNotch: FC<ImageWithNotchType> = ({ src }) => {
  return (
    <Wrapper>
      <GlobalStyle />
      <ScreenshotImage src={src} />
      <Notch />
    </Wrapper>
  )
}

export default ImageWithNotch
