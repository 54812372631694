import { FunctionComponent, MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type NextPreviousControlsType = {
  isNextEnabled: boolean
  isPreviousEnabled: boolean
  onNextClick: Function
  onPreviousClick: Function
}

const RootDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  font-size: 22px;
`
const Arrow = styled.div`
  width: 17px;
  @media screen and (max-width: 450px) {
    font-size: 18px;
  }
`
const Text = styled.b`
  @media screen and (max-width: 450px) {
    font-size: 18px;
  }
`
type NextIconTextDivType = {
  onClick?: (MouseEventHandler<HTMLDivElement> & Function) | undefined
  isEnabled: boolean
}

const NextIconTextBtn = styled.button<NextIconTextDivType>`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-size: inherit;
  gap: 8px;
  cursor: pointer;
  color: ${({ isEnabled }) => (isEnabled ? '#6360ff' : '#2c2c2c')};
`

const NextPreviousControls: FunctionComponent<NextPreviousControlsType> = ({
  onNextClick,
  onPreviousClick,
  isNextEnabled,
  isPreviousEnabled,
}) => {
  const { t } = useTranslation()
  return (
    <RootDiv>
      <NextIconTextBtn
        onClick={() => {
          onPreviousClick()
        }}
        isEnabled={isPreviousEnabled}
        disabled={!isPreviousEnabled}
      >
        <Arrow>{'\u{100BF6}'}</Arrow>
        <Text>{t('previous')}</Text>
      </NextIconTextBtn>
      <NextIconTextBtn
        onClick={() => {
          onNextClick()
        }}
        isEnabled={isNextEnabled}
        disabled={!isNextEnabled}
      >
        <Text>{t('next')}</Text>
        <Arrow>{'\u{10018A}'}</Arrow>
      </NextIconTextBtn>
    </RootDiv>
  )
}

export default NextPreviousControls
