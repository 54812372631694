import { FunctionComponent } from 'react'
import styled from 'styled-components'

export type TeamCirclesType = {
  className?: string
}

const FrameChild = styled.img`
  width: 18.125rem;
  height: 18.125rem;
  position: relative;
  border-radius: 50%;
  object-fit: cover;
`
const PierluigiCifani = styled.b`
  position: relative;
  line-height: 2rem;
  font-weight: 700;
  white-space: nowrap;
  @media screen and (max-width: 450px) {
    font-size: 1.25rem;
  }
`
const CoFounderIos = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 1.25rem;
  font-family: 'SF Compact';
  color: #c2c2c2;
  @media screen and (max-width: 450px) {
    font-size: 1rem;
  }
`
const PierluigiCifaniParent = styled.div`
  width: 11.938rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
`
const EllipseParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4rem;
  @media screen and (max-width: 450px) {
    gap: 2rem;
  }
`
const QuiqueLvarezParent = styled.div`
  width: 16.938rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0rem 1.25rem;
  box-sizing: border-box;
  gap: 1.5rem;
`
const LeandroMilano = styled.b`
  height: 1.875rem;
  position: relative;
  display: inline-block;
  @media screen and (max-width: 450px) {
    font-size: 1.25rem;
  }
`
const LeandroMilanoParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0rem 0.312rem;
  gap: 1.5rem;
`
const EllipseContainer = styled.div`
  width: 18.125rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4rem;
  @media screen and (max-width: 450px) {
    gap: 2rem;
  }
`
const RdLead = styled.div`
  position: relative;
  font-size: 1.25rem;
  font-family: 'SF Compact';
  color: #c2c2c2;
  display: inline-block;
  min-width: 5.25rem;
  @media screen and (max-width: 450px) {
    font-size: 1rem;
  }
`
const JordiCondominasParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
`
const JordiSerra = styled.b`
  height: 1.875rem;
  position: relative;
  font-weight: 700;
  display: inline-block;
  min-width: 7.875rem;
  white-space: nowrap;
  @media screen and (max-width: 450px) {
    font-size: 1.25rem;
  }
`
const IosDeveloper = styled.div`
  position: relative;
  font-size: 1.25rem;
  font-family: 'SF Compact';
  color: #c2c2c2;
  display: inline-block;
  min-width: 7.563rem;
  @media screen and (max-width: 450px) {
    font-size: 1rem;
  }
`
const VctorVentura = styled.b`
  align-self: stretch;
  position: relative;
  font-weight: 700;
  @media screen and (max-width: 450px) {
    font-size: 1.25rem;
  }
`
const DesignLead = styled.div`
  width: 6.688rem;
  position: relative;
  font-size: 1.25rem;
  font-family: 'SF Compact';
  color: #c2c2c2;
  display: inline-block;
  min-width: 6.688rem;
  @media screen and (max-width: 450px) {
    font-size: 1rem;
  }
`
const VctorVenturaParent = styled.div`
  width: 11.125rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
`
const FrameParent = styled.div`
  width: 94.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem 7.5rem;
  box-sizing: border-box;
  gap: 6rem 12.437rem;
  min-height: 60rem;
  max-width: 100%;
  @media screen and (max-width: 1200px) {
    gap: 6.188rem;
    padding-left: 3.75rem;
    padding-right: 3.75rem;
    box-sizing: border-box;
  }
  @media screen and (max-width: 750px) {
    gap: 3.125rem;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    box-sizing: border-box;
  }
  @media screen and (max-width: 450px) {
    gap: 1.563rem;
  }
`
const TeamInnerRoot = styled.section`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem 0rem 2.5rem;
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  font-size: 1.563rem;
  color: #fff;
  font-family: 'Space Grotesk';
`

const TeamCircles: FunctionComponent<TeamCirclesType> = ({
  className = '',
}) => {
  return (
    <TeamInnerRoot className={className}>
      <FrameParent>
        <EllipseParent>
          <FrameChild loading="lazy" alt="" src="/pier.jpg" />
          <PierluigiCifaniParent>
            <PierluigiCifani>Pierluigi Cifani</PierluigiCifani>
            <CoFounderIos>{`Co-founder & Apple Platforms lead`}</CoFounderIos>
          </PierluigiCifaniParent>
        </EllipseParent>
        <EllipseParent>
          <FrameChild loading="lazy" alt="" src="/quique.jpg" />
          <QuiqueLvarezParent>
            <PierluigiCifani>Quique Álvarez</PierluigiCifani>
            <CoFounderIos>{`Co-founder & Android lead`}</CoFounderIos>
          </QuiqueLvarezParent>
        </EllipseParent>
        <EllipseContainer>
          <FrameChild alt="" src="/leandro.jpg" />
          <LeandroMilanoParent>
            <LeandroMilano>Leandro Milano</LeandroMilano>
            <CoFounderIos>Business Development Manager</CoFounderIos>
          </LeandroMilanoParent>
        </EllipseContainer>
        <EllipseParent>
          <FrameChild alt="" src="/jordic.jpg" />
          <JordiCondominasParent>
            <LeandroMilano>Jordi Condominas</LeandroMilano>
            <RdLead>{`R&D lead`}</RdLead>
          </JordiCondominasParent>
        </EllipseParent>
        <EllipseParent>
          <FrameChild alt="" src="/jordis.jpg" />
          <JordiCondominasParent>
            <JordiSerra>Jordi Serra</JordiSerra>
            <IosDeveloper>Frontend lead</IosDeveloper>
          </JordiCondominasParent>
        </EllipseParent>
      </FrameParent>
    </TeamInnerRoot>
  )
}

export default TeamCircles
