import { FC, FunctionComponent, useCallback, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import FreeConsultationButton from './FreeConsultationButton'
import { TLBLink } from './Footer'
import { VIDEOASK_URL } from '../App'
import { useTranslation } from 'react-i18next'

export type WebsiteHeaderType = {
  className?: string
}

const Logo1Icon = styled.img`
  height: 32px;
  width: 146px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
`
const OurMethod = styled.a`
  text-decoration: none;
  position: relative;
  line-height: 26.5px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 120px;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: #ddd;
  }
`
const TheTeam = styled(OurMethod)`
  min-width: 95px;
`
const OurMethodParent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  @media screen and (max-width: 850px) {
    display: none;
  }
`
const FrameParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 38px;
  max-width: 100%;
  @media screen and (max-width: 850px) {
    width: 258px;
    gap: 19px;
  }
`
const Logo1Parent = styled.header`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 14px 16px;
  top: 0;
  gap: 20px;
  max-width: 100%;
  text-align: left;
  font-size: 21px;
  color: #fff;
  font-family: 'Space Grotesk';
`
const HomeInnerRoot = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  top: 0;
  width: 100%;
  background-color: #333;
  color: white;
  text-align: center;
  padding: 1rem 0;
  z-index: 1000;
  backdrop-filter: blur(108px);
  background-color: rgba(0, 0, 0, 0.4);
`

const FrameChevron = styled.button`
  padding: 8px;
  display: none;
  background-color: transparent;
  border-width: 0px;

  &:hover {
    background-color: #222;
    border-radius: 50%;
  }

  @media screen and (max-width: 850px) {
    display: block;
  }
`

type T = {
  $exp: boolean
}

const IconH3 = styled.h3<T>`
  margin: 0;
  font-size: 21px;
  font-weight: 457;
  font-family: 'SF Compact';
  color: #555;
  transform: ${({ $exp }) => ($exp ? 'rotate(180deg)' : 'none')};
  transition: all 0.2s;
  cursor: pointer;

  @media screen and (max-width: 450px) {
    font-size: 23px;
  }
`

const WebsiteHeader: FunctionComponent<WebsiteHeaderType> = () => {
  const [expanded, setExpanded] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const onOurMethodTextClick = useCallback(() => {
    // Please sync "Our Method" to the project
    navigate('/our-method')
    setExpanded(false)
  }, [])

  const onTheTeamText1Click = useCallback(() => {
    // Please sync "Team" to the project
    navigate('/team')
    setExpanded(false)
  }, [])

  const onLogoClick = useCallback(() => {
    navigate('/')
    setExpanded(false)
  }, [])

  return (
    <HomeInnerRoot>
      <Logo1Parent>
        <Logo1Icon
          loading="lazy"
          alt=""
          src="/logo-1.svg"
          onClick={onLogoClick}
        />
        <FrameParent>
          <OurMethodParent>
            <OurMethod onClick={onOurMethodTextClick}>
              {t('header.ourMethod')}
            </OurMethod>
            <TheTeam onClick={onTheTeamText1Click}>
              {t('header.theTeam')}
            </TheTeam>
          </OurMethodParent>
          <FreeConsultationButton />
        </FrameParent>
        <FrameChevron
          onClick={() => {
            setExpanded((expanded) => !expanded)
          }}
        >
          <IconH3 $exp={expanded}>{'\udbc0\udd88'}</IconH3>
        </FrameChevron>
      </Logo1Parent>
      <DropdownMenu isOpen={expanded} setIsOpen={setExpanded} />
    </HomeInnerRoot>
  )
}

interface DropdownMenuType {
  isOpen: boolean
  setIsOpen: Function
}

const DropdownContent = styled.div`
  display: flex;
  flex-direction: column;
`

const DropdownRoot = styled.div<{ isOpen: boolean }>`
  position: absolute;
  z-index: 1000;
  top: 100px;
  width: 100%;
  transition: all 0.3s ease;
  display: grid;
  grid-template-rows: ${({ isOpen }) => (isOpen ? '1fr' : '0fr')};
  background-color: #222;

  > * {
    overflow: hidden;
  }
`

const MenuRow = styled(TLBLink)`
  border: 0px;
  background-color: inherit;
  color: inherit;
  font-family: inherit;
  margin: 0;
  font-size: 1.3125rem;
  padding: 1.5rem 2.125rem;
  font-weight: 300;
  text-align: left;
  outline: 0.5px solid #555;
  cursor: pointer;

  &:hover {
    background-color: #666;
  }
`

const DropdownMenu: FC<DropdownMenuType> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation()
  return (
    <DropdownRoot isOpen={isOpen}>
      <DropdownContent>
        <MenuRow
          onClick={() => {
            setIsOpen(false)
          }}
          to={'/our-method'}
        >
          {t('header.ourMethod')}
        </MenuRow>
        <MenuRow
          onClick={() => {
            setIsOpen(false)
          }}
          to={'/team'}
        >
          {t('header.theTeam')}
        </MenuRow>
        <MenuRow
          onClick={() => {
            setIsOpen(false)
          }}
          to={VIDEOASK_URL}
        >
          {t('header.freeConsultation')}
        </MenuRow>
      </DropdownContent>
    </DropdownRoot>
  )
}

export default WebsiteHeader
