import { FunctionComponent } from 'react'
import styled from 'styled-components'

export type TestimonialType = {
  className?: string
  ellipse1?: string
  davidOkuniev?: string
  typeformCoFounder?: string
  prop?: string
  theLeftBitTeamNotOnlyHelp?: string
}

const FrameChild = styled.img`
  height: 64px;
  width: 64px;
  position: relative;
  border-radius: 50%;
  object-fit: cover;
`
const NameTitleB = styled.b`
  height: 30px;
  position: relative;
  display: inline-block;
  @media screen and (max-width: 450px) {
    font-size: 20px;
  }
`
const RoleDiv = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 20px;
  font-family: 'SF Compact';
  color: #c2c2c2;
  @media screen and (max-width: 450px) {
    font-size: 16px;
  }
`
const ProfileTextDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 392px;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    min-width: 100%;
  }
`
const EllipseParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 1px 0px 0px;
  box-sizing: border-box;
  gap: 28px;
  max-width: 100%;
`
const Icon = styled.img`
  width: 25.5px;
  height: 18px;
  position: relative;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`
const TheLeftBit = styled.i`
  flex: 1;
  position: relative;
  display: inline-block;
  font-style: italic;
  min-width: 392px;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    font-size: 22px;
    min-width: 100%;
  }
`
const FrameGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 28px;
  max-width: 100%;
  font-size: 28px;
  font-family: 'New York';

  @media screen and (max-width: 850px) {
    flex-direction: column;
    gap: 8px;
    padding-left: 0px;
  }
`
const FrameParentRoot = styled.div`
  width: 696.5px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 28px;
  max-width: 100%;
  text-align: left;
  font-size: 25px;
  color: #222;
  font-family: 'Space Grotesk';
`

const Testimonial: FunctionComponent<TestimonialType> = ({
  className = '',
  ellipse1,
  davidOkuniev,
  typeformCoFounder,
  prop,
  theLeftBitTeamNotOnlyHelp,
}) => {
  return (
    <FrameParentRoot className={className}>
      <EllipseParent>
        <FrameChild loading="lazy" alt="" src={ellipse1} />
        <ProfileTextDiv>
          <NameTitleB>{davidOkuniev}</NameTitleB>
          <RoleDiv>{typeformCoFounder}</RoleDiv>
        </ProfileTextDiv>
      </EllipseParent>
      <FrameGroup>
        <Wrapper>
          <Icon loading="lazy" alt="" src={prop} />
        </Wrapper>
        <TheLeftBit>{theLeftBitTeamNotOnlyHelp}</TheLeftBit>
      </FrameGroup>
    </FrameParentRoot>
  )
}

export default Testimonial
