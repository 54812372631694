import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { createGlobalStyle } from 'styled-components'

const Global = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
`

export const breakpoints = {
  XSMALL: '48rem',
  SMALL: '64rem',
}

const RootSection = styled.section`
  background-color: white;
  height: auto;

  @media screen and (width > ${breakpoints.SMALL}) {
    height: min-content;
  }
`

const MockupImage = styled.img`
  width: 100%;
  object-fit: contain;

  /* screens bigger than 800px */
  @media screen and (width > ${breakpoints.SMALL}) {
    position: absolute;
    top: 7.75rem;
    right: 0rem;
    height: 45vw;
    width: auto;
  }
`

const BlackBox = styled.div`
  background-color: black;
  height: 100%;
  max-height: 100%;

  /* screens bigger than 800px */
  @media screen and (width > ${breakpoints.SMALL}) {
    height: 40vw;
  }
`

const TextBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 2rem 4rem 2rem;
  gap: 2.1875rem;
  color: #fff;
  z-index: 100;
  max-width: 100%;
  background-color: black;

  /* screens bigger than 800px */
  @media screen and (width > ${breakpoints.SMALL}) {
    max-width: 60vw;
  }
`

const TitleBox = styled.div``

const TitleH1 = styled.div`
  margin: 0;
  font-family: 'Feature Display';
  font-size: max(7vw, 3rem);
  font-weight: 200;
`

const SubtitleBox = styled.div``
const SubtitleH3 = styled.h3`
  margin: 0;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
  font-size: max(1rem, 1.5vw);
`

const OpeningSection: FC = () => {
  const { t } = useTranslation()

  const imageSet = useCallback(() => {
    const images = {
      src: 'lazyweather-mockup-1000.png',
      srcset: [
        {
          src: 'lazyweather-mockup-1400.png',
          width: 2800,
        },
        {
          src: 'lazyweather-mockup-1000.png',
          width: 2000,
        },
        {
          src: 'lazyweather-mockup-800.png',
          width: 1600,
        },
        {
          src: 'lazyweather-mockup-500.png',
          width: 1000,
        },
      ],
    }
    return {
      src: images.src,
      srcSet: images.srcset
        .map(({ src, width }) => `${src} ${width}w`)
        .join(', '),
    }
  }, [])

  return (
    <>
      <Global />
      <RootSection>
        <BlackBox>
          <MockupImage {...imageSet()} />
          <TextBox>
            <TitleBox>
              <TitleH1>{t('section.opening.titleLine1')}</TitleH1>
              <TitleH1>{t('section.opening.titleLine2')}</TitleH1>
            </TitleBox>
            <SubtitleBox>
              <SubtitleH3>{t('section.opening.subtitle1')}</SubtitleH3>
              <SubtitleH3>{t('section.opening.subtitle2')}</SubtitleH3>
              <SubtitleH3>{t('section.opening.subtitle3')}</SubtitleH3>
            </SubtitleBox>
          </TextBox>
        </BlackBox>
      </RootSection>
    </>
  )
}

export default OpeningSection
