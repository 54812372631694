import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    * {
      box-sizing: border-box;
    }
    body {
      margin: 0; line-height: normal;
      font-family: var(--font-sf-compact);
    }
    :root {

/* fonts */
--font-sf-compact: 'SF Compact';
--font-feature-display: 'Feature Display';
--font-space-grotesk: 'Space Grotesk';
--font-hubot-sans: 'Hubot-Sans';

/* font sizes */
--font-size-13xl: 2rem;
--font-size-2xl: 1.313rem;
--font-size-mid: 1.063rem;
--font-size-lg: 1.125rem;
--font-size-5xl: 1.5rem;
--font-size-lgi: 1.188rem;
--font-size-3xl: 1.375rem;
--font-size-89xl: 6.75rem;
--font-size-35xl: 3.375rem;

/* Colors */
--color-black: #000;
--color-white: #fff;
--color-gainsboro-100: #e6e6e6;
--color-gainsboro-200: #d9d9d9;
--color-darkslategray-100: #444;
--color-darkslategray-200: #424242;
--color-darkgray: #b0b0b0;
--color-gray-100: #2b2b2b;
--color-gray-200: #222;
--color-gray-300: rgba(0, 0, 0, 0.4);
--color-gray-400: rgba(255, 255, 255, 0.7);
--color-lightgray-100: #d4d4d4;
--color-lightgray-200: #d2d2d2;
--color-dimgray: #656565;
--color-silver: #b9b9b9;

/* Gaps */
--gap-45xl: 4rem;
--gap-base: 1rem;
--gap-13xl: 2rem;
--gap-165xl: 11.5rem;
--gap-27xl: 2.875rem;
--gap-73xl: 5.75rem;
--gap-6xs: 0.437rem;
--gap-5xs: 0.5rem;
--gap-16xl: 2.187rem;
--gap-mid: 1.062rem;
--gap-4xs: 0.562rem;
--gap-xl: 1.25rem;
--gap-19xl: 2.375rem;
--gap-lgi: 1.187rem;

/* Paddings */
--padding-270xl: 18.062rem;
--padding-131xl: 9.375rem;
--padding-93xl: 7rem;
--padding-45xl: 4rem;
--padding-103xl: 7.625rem;
--padding-xl: 1.25rem;
--padding-28xl: 2.937rem;
--padding-169xl: 11.75rem;
--padding-56xl: 4.687rem;
--padding-54xl: 4.562rem;
--padding-13xl: 2rem;
--padding-27xl: 2.875rem;
--padding-262xl: 17.562rem;
--padding-8xl: 1.687rem;
--padding-23xl: 2.625rem;
--padding-51xl: 4.375rem;
--padding-121xl: 8.75rem;
--padding-71xl: 5.625rem;
--padding-102xl: 7.562rem;
--padding-62xl: 5.062rem;
--padding-34xl: 3.312rem;
--padding-40xl: 3.687rem;
--padding-64xl: 5.187rem;
--padding-86xl: 6.562rem;
--padding-2xl: 1.312rem;
--padding-5xl: 1.5rem;
--padding-49xl: 4.25rem;
--padding-29xl: 3rem;
--padding-sm-5: 0.843rem;
--padding-16xl: 2.187rem;
--padding-4xl: 1.437rem;
--padding-base: 1rem;
--padding-lgi: 1.187rem;
--padding-mini: 0.937rem;
--padding-sm: 0.875rem;

/* Border radiuses */
--br-93xl: 112px;
--br-77xl: 96px;
--br-21xl: 40px;
--br-13xl: 32px;
--br-5xs: 8px;

}
`
