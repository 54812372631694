import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Div = styled.div`
  width: 1.563rem;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-lg);
  }
`
const TechTitle = styled.div`
  white-space: nowrap;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-lg);
  }
`
const Parent1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
`
const TechH4 = styled.h4`
  margin: 0;
  font-weight: 300;
  display: inline-block;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-lgi);
  }
`
const TechBoxDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--font-size-5xl);
  color: var(--color-gray-400);
`
const FrameGroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0rem var(--padding-86xl) 0rem 0rem;
  box-sizing: border-box;
  gap: var(--gap-45xl);
  max-width: 100%;
  @media screen and (max-width: 450px) {
    gap: var(--gap-13xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
    flex: 1;
    min-width: 42vw;
  }
`
const Div1 = styled.div`
  width: 1.75rem;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-lg);
  }
`
const NativeIosApps = styled.div`
  align-self: stretch;
  height: 1.625rem;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  white-space: nowrap;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-lg);
  }
`
const FrameChild = styled.img`
  width: 1.488rem;
  height: 1.625rem;
  position: relative;
`
const Div2 = styled.div`
  width: 1.875rem;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-lg);
  }
`
const FrameParent1 = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  row-gap: var(--gap-45xl);
  column-gap: var(--gap-base);
  max-width: 100%;
  @media screen and (max-width: 1050px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 450px) {
    justify-content: revert;
  }
`
const OurMethodInnerRoot = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  padding: 0rem var(--padding-xl) var(--padding-62xl);
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-3xl);
  color: var(--color-lightgray-200);
  font-family: var(--font-sf-compact);
  @media screen and (max-width: 750px) {
    padding-bottom: var(--padding-34xl);
    box-sizing: border-box;
  }
`

const TechStack: FunctionComponent = () => {
  const { t } = useTranslation()
  return (
    <OurMethodInnerRoot>
      <FrameParent1>
        <FrameGroup>
          <Parent1>
            <Div>􀤆</Div>
            <TechTitle>{t('section.ourMethod.webTechnologies')}</TechTitle>
          </Parent1>
          <TechBoxDiv>
            <TechH4>React</TechH4>
            <TechH4>Vue</TechH4>
            <TechH4>NextJS</TechH4>
            <TechH4>GatsbyJS</TechH4>
            <TechH4>GraphQL</TechH4>
          </TechBoxDiv>
        </FrameGroup>
        <FrameGroup>
          <Parent1>
            <Div1>􀫊</Div1>
            <NativeIosApps>
              {t('section.ourMethod.nativeIosApps')}
            </NativeIosApps>
          </Parent1>
          <TechBoxDiv>
            <TechH4>{'Swift'}</TechH4>
            <TechH4>Objective-C</TechH4>
            <TechH4>SwiftUI</TechH4>
            <TechH4>UIKit</TechH4>
          </TechBoxDiv>
        </FrameGroup>
        <FrameGroup>
          <Parent1>
            <FrameChild loading="lazy" alt="" src="/frame-95.svg" />
            <TechTitle>{t('section.ourMethod.nativeAndroidApps')}</TechTitle>
          </Parent1>
          <TechBoxDiv>
            <TechH4>Kotlin</TechH4>
            <TechH4>Java</TechH4>
            <TechH4>Compose</TechH4>
            <TechH4>XML</TechH4>
          </TechBoxDiv>
        </FrameGroup>
        <FrameGroup>
          <Parent1>
            <Div2>􀩼</Div2>
            <TechTitle>{t('section.ourMethod.backendDevelopment')}</TechTitle>
          </Parent1>
          <TechBoxDiv>
            <TechH4>Go</TechH4>
            <TechH4>Django</TechH4>
            <TechH4>Kotlin</TechH4>
            <TechH4>NodeJS</TechH4>
            <TechH4>Vapor</TechH4>
            <TechH4>PostgreSQL</TechH4>
          </TechBoxDiv>
        </FrameGroup>
      </FrameParent1>
    </OurMethodInnerRoot>
  )
}

export default TechStack
