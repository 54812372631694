import { FC } from 'react'
import styled, { keyframes } from 'styled-components'

const spin = keyframes`
  from {
    transform: rotate(0deg); 
  }
	to {
    transform: rotate(359deg);
  }
`

const LoadingDiv = styled.div`
  border-bottom: 4px solid rgba(0, 0, 0, 0.2);
  border-left: 4px solid rgba(0, 0, 0, 0.2);
  border-right: 4px solid rgba(0, 0, 0, 0.2);
  border-top: 4px solid rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  height: 32px;
  width: 32px;
  animation: ${spin} 0.6s infinite linear;
  text-indent: 999em;
  margin: 3em auto;
`

const Spinner: FC = () => {
  return <LoadingDiv />
}

export default Spinner
